import {
  useMediaQuery,
  Typography,
  useTheme,
  Button,
  Modal,
  Box,
  Input,
  TextField,
  Icon,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import emailjs from "emailjs-com";
import gravix from "../assets/gravix.mp4";
import Content from "../assets/content";
import { SetStateAction, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";

function Media() {
  return (
    <video
      style={{ width: "100%" }}
      playsInline
      muted
      src={gravix}
      autoPlay={true}
    />
  );
}
function RequestDemoButton() {
  enum SendStatus {
    unSent,
    Sending,
    Success,
    Failure,
  }
  const firstTime = useRef(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [serverError, setServerError] = useState("");
  const [sendStatus, setSendStatus] = useState(SendStatus.unSent);
  const emailValidationRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+)$/;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className="mt3">
      <Button
        variant="outlined"
        style={{ borderRadius: 0 }}
        onClick={() => setOpen(true)}
      >
        Request a Demo
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setError(null);
          setServerError("");
          setEmail("");
          setSendStatus(SendStatus.unSent);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="bg-white flex flex-column pa4 ba"
          style={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : 500,
            border: "2px solid #000",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Request a Demo
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            See how your products can be onboarded to unlock instant quotes on
            any projects. Save 90% time in estimation and takeoff with
            Struction.
          </Typography>
          <TextField
            style={{ marginTop: 20, marginBottom: 20 }}
            error={error != null && error !== ""}
            helperText={error != null ? error : null}
            color="primary"
            label="Enter your email"
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
              if (
                event.target.value != null &&
                emailValidationRegex.test(event.target.value) === true &&
                error !== null
              ) {
                setError(null);
              } else if (
                emailValidationRegex.test(event.target.value) === false
              ) {
                if (!firstTime.current) {
                  setError("Incorrect email format. Please re-enter.");
                }
              }
            }}
            variant="standard"
          />
          <LoadingButton
            endIcon={sendStatus === SendStatus.Success && <DoneIcon />}
            style={{ borderRadius: 0 }}
            loading={sendStatus === SendStatus.Sending}
            variant="outlined"
            color={
              sendStatus === SendStatus.Success
                ? "success"
                : sendStatus === SendStatus.Failure
                ? "error"
                : "primary"
            }
            onClick={() => {
              if (email != null && emailValidationRegex.test(email) === true) {
                setSendStatus(SendStatus.Sending);
                emailjs
                  .send(
                    "service_axo210u",
                    "template_1nhwjpm",
                    { message: email },
                    "94Yil8VZAgPyoZ8QF"
                  )
                  .then((_: any) => {
                    setSendStatus(SendStatus.Success);
                    setTimeout(() => {
                      setOpen(false);
                      setError(null);
                      setServerError("");
                      setSendStatus(SendStatus.unSent);
                      setEmail("");
                    }, 1000);
                  })
                  .catch((error: any) => {
                    setSendStatus(SendStatus.Failure);
                    setServerError(error.text);
                  });
              } else {
                firstTime.current = false;
                setError("Incorrect email format. Please re-enter.");
              }
            }}
          >
            Request a Demo
          </LoadingButton>
          {serverError && (
            <Typography
              color="error"
              variant="body1"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              {serverError}
            </Typography>
          )}
        </div>
      </Modal>
    </div>
  );
}
function Copy() {
  return (
    <>
      <div className="mb3">
        <Typography variant="h2" lineHeight={1}>
          <strong>{Content.home.heroTitle}</strong>
        </Typography>
      </div>
      <Typography variant="h6">{Content.home.heroDescription}</Typography>
      <RequestDemoButton />
    </>
  );
}

export default function HeroSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {isMobile ? (
        <div className="flex flex-column" style={{ padding: "7%" }}>
          <div style={{ width: "100%", padding: "10% 0 10% 0" }}>
            <Media />
          </div>
          <div className="w-100" style={{ paddingBottom: "7%" }}>
            <Copy />
          </div>
        </div>
      ) : (
        <div
          className="section pb4 pt7"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <div
            style={{
              paddingLeft: "5%",
              paddingBottom: "10%",
              width: "50%",
            }}
          >
            <Copy />
          </div>
          <div style={{ width: "50%" }}>
            <Media />
          </div>
        </div>
      )}
    </>
  );
}

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavBar from "./NavBar";

const RIGHT_FRAME_WIDTH = 100;

const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1190,
      xl: 1666,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h2: {
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    h3: {
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
      }
        `,
    },
  },
});

function FrameLayout({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.only("lg"));
  const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const marginStyles = isLargeScreen
    ? {
        marginRight: RIGHT_FRAME_WIDTH,
        minWidth: theme.breakpoints.values.lg,
      }
    : isXLScreen
    ? { marginRight: "6%" }
    : {};
  return (
    <div className="flex relative flex-column h-100" style={marginStyles}>
      {children}
    </div>
  );
}

export default function LandingLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeProvider theme={responsiveFontSizes(lightTheme)}>
      <div style={{ overflowX: "clip" }}>
        <FrameLayout>
          <NavBar />
          <div
            className="z-2 w-100 h-100 flex flex-column justify-between"
            style={{ flexGrow: 1 }}
          >
            {children}
          </div>
        </FrameLayout>
      </div>
    </ThemeProvider>
  );
}

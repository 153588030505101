const Content = {
  home: {
    heroTitle: "Civil projects quoted instantly using AI",
    heroDescription:
      "Effortlessly generate 3D visualized and product-specific custom quotes for your civil project plans in minutes ",
    product: [
      {
        title: "Upload drawings",
        description:
          "Upload the entire project drawing set and Struction will automatically identify pages containing details and specifications related to your products.",
      },
      {
        title: "Perform automated takeoff",
        description:
          "Verify AI-extracted dimensions and specifications with a click of a button for each page related to your products. Alternatively, use an intuitive UI to takeoff dimensions manually.",
      },
      {
        title: "Receive a custom quote",
        description:
          "Struction maps the extracted dimensions and specifications from the drawings to your provided product data to construct a 3D visualization of the project. Combined with current pricing, the result is a accurate, near-instant quote.",
      },
    ],
  },
  about: {
    missionTitle: "Our Mission",
    missionDescription:
      "Struction is supercharging the supply base of construction by de-risking the takeoff, preliminary design, and quoting process. Instead of spending days bidding projects with no guarantees, our customers quote projects more than 30x faster for less than 10% the cost. We will scale this capability to Subcontractors, General Contractors, and eventually Owners. Someday soon, pricing any construction project will take minutes instead of months.",
    teamTitle: "Meet our team",
    samName: "Sam Tang",
    samTitle: "CTO",
    samBio:
      "As a Senior System Engineer at Meta, Sam developed novel Augmented Reality input modalities in Spatial Interfaces. Before that, he was a full-stack developer that designed and built 0->1 community content experiences in Facebook Marketplace. ",
    joeName: "Joseph Rainey",
    joeTitle: "CEO",
    joeBio:
      "Joe, the Facilities and Construction Lead for Sierra Space, has a pending patent for a pre-fabricated mechanically stabilized earth retaining system. Additionally, he designed the largest known contiguous volume of ISO 9 clean production space in the USA.",
  },
  nav: {
    homeLabel: "Home",
    aboutLabel: "About",
  },
};

export default Content;

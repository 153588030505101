import LandingLayout from "./LandingLayout";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <LandingLayout>
      <div className="flex flex-column w-100 h-100 items-center justify-center pa5">
        <Typography variant="h2">Oops!</Typography>
        <Typography variant="body1" className="pa3">
          An unknown error occurred, click below to return home
        </Typography>
        <Link
          to="/"
          style={{ zIndex: 5, color: "black", textDecoration: "none" }}
        >
          <div className="pa3 ba">Return home</div>
        </Link>
      </div>
    </LandingLayout>
  );
}

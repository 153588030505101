import wall from "../assets/wall.png";
import prelim from "../assets/prelim.png";
import quote from "../assets/quote.png";
import section1 from "../assets/section1.svg";
import section2 from "../assets/section2.svg";
import section3 from "../assets/section3.svg";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import Content from "../assets/content";

type Props = {
  sectionIndex: number;
};

// distance from the top of page to first section
const FIRST_SECTION_VERTICAL_OFFSET_MOBILE = 1100;
const SECTION_HEIGHT_MOBILE = 580;
const SECTION_HEIGHT_DESKTOP = 800;
const FIRST_SECTION_VERTICAL_OFFSET_DESKTOP = 1200;

export default function ProductSection({ sectionIndex }: Props) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [scrollDistance, setScrollDistance] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrollDistance(latest);
  });
  let y =
    -(
      (isMobile
        ? FIRST_SECTION_VERTICAL_OFFSET_MOBILE
        : FIRST_SECTION_VERTICAL_OFFSET_DESKTOP) +
      sectionIndex * (isMobile ? SECTION_HEIGHT_MOBILE : SECTION_HEIGHT_DESKTOP)
    ) +
    scrollDistance +
    windowHeight;
  y = y < 0 ? 0 : y;

  const opacity = useMemo(() => {
    const start = 100;
    const end = windowHeight - start;
    if (y < start) {
      return y * 0.01;
    } else if (y > start && y < end) {
      return 1;
    } else if (y > end && y < end + 200) {
      return 1 - (y - end) * 0.01;
    }
    return 0;
  }, [windowHeight, y]);

  const icon =
    sectionIndex === 0 ? section1 : sectionIndex === 1 ? section2 : section3;
  const image = sectionIndex === 0 ? wall : sectionIndex === 1 ? prelim : quote;
  const sectionHeader = Content.home.product[sectionIndex].title;
  const sectionBody = Content.home.product[sectionIndex].description;

  return (
    <div
      className={isMobile ? "pv3" : "pv6"}
      style={{
        height: isMobile ? 580 : 800,
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          padding: isMobile ? "7%" : 0,
          paddingLeft: isMobile ? "7%" : "5%",
          width: isMobile ? "100%" : "50%",
        }}
      >
        <img src={image} alt="wall" />
      </div>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: isMobile ? "100%" : "50%",
          flexDirection: "column",
        }}
      >
        {!isMobile && <div style={{ height: "30%", width: "100%" }} />}
        <AnimatePresence>
          <motion.div
            initial={{ x: 300 }}
            whileInView={{ x: 0 }}
            viewport={{ once: true }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
            style={{
              height: isMobile ? 65 : 100,
              width: "150%",
              display: "flex",
              position: "relative",
              flexDirection: "row",
              paddingLeft: isMobile ? "7%" : "20%",
            }}
          >
            <img
              src={icon}
              style={{
                width: isMobile ? 49 : 71,
                height: isMobile ? 40 : 60,
                paddingTop: isMobile ? 0 : 5.5,
                right: -2,
                zIndex: -1,
                top: isMobile ? 9 : 0,
                position: "relative",
              }}
              alt={"section1"}
            />
            <Typography
              color="text.primary"
              variant="h4"
              style={{
                minWidth: 500,
                paddingLeft: isMobile ? 5 : 10,
                flexGrow: 1,
                height: isMobile ? 32 : 40,
                paddingRight: 140,
                borderBottom: "1px solid black",
                marginBottom: 60,
                ...(isMobile ? { fontSize: "1.3rem", paddingTop: 7 } : {}),
              }}
            >
              <strong>{sectionHeader}</strong>
            </Typography>
          </motion.div>
        </AnimatePresence>
        <motion.div
          style={{ opacity, y: (1 - opacity) * (isMobile ? 25 : 50) }}
        >
          <Typography
            color="text.primary"
            variant="body1"
            style={{
              paddingLeft: isMobile ? "7%" : "20%",
              paddingRight: "10%",
            }}
          >
            {sectionBody}
          </Typography>
        </motion.div>
      </div>
    </div>
  );
}

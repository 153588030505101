import LandingLayout from "./LandingLayout";
import SamProfilePhoto from "../assets/sam.jpg";
import JoeProfilePhoto from "../assets/joe.jpg";
import Content from "../assets/content.js";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

function Bio({
  name,
  title,
  description,
  profile,
}: {
  name: string;
  title: string;
  description: string;
  profile: string;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      className="flex flex-column items-center pa4 ba w-100"
      style={{
        height: 500,
        width: isSmallScreen ? "90%" : 400,
        minWidth: 300,
        margin: "3%",
      }}
    >
      <img alt="profile" src={profile} className="w-50 br-100" />
      <Typography variant="h4" className="pt3">
        <strong>{name}</strong>
      </Typography>
      <Typography variant="h5" className="pb3">
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </div>
  );
}
export default function About() {
  const theme = useTheme();
  const notLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <LandingLayout>
      <div
        className={
          "flex flex-column w-100 items-center h-100 " +
          (notLargeScreen ? "" : "br")
        }
      >
        <div
          className={
            "flex flex-column justify-center tc " +
            (isSmallScreen ? "pa3" : "mh6")
          }
          style={{
            paddingTop: isSmallScreen ? "20%" : 200,
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingBottom: "10%",
          }}
        >
          <Typography variant="h3" style={{ paddingBottom: 20 }}>
            {Content.about.missionTitle}
          </Typography>
          <Typography variant="body1">
            {Content.about.missionDescription}
          </Typography>
        </div>
        {!isSmallScreen && (
          <div className="bt" style={{ width: 500, borderWidth: 1 }} />
        )}
        <div
          className={
            "flex flex-column items-center justify-center w-100 " +
            (isSmallScreen ? "" : "pa5")
          }
        >
          <Typography variant="h3">{Content.about.teamTitle}</Typography>
          <div
            className={
              "flex justify-center items-center w-100 " +
              (isSmallScreen ? "flex-column" : "flex-row")
            }
          >
            <Bio
              name={Content.about.joeName}
              description={Content.about.joeBio}
              title={Content.about.joeTitle}
              profile={JoeProfilePhoto}
            />
            <Bio
              name={Content.about.samName}
              description={Content.about.samBio}
              title={Content.about.samTitle}
              profile={SamProfilePhoto}
            />
          </div>
        </div>
      </div>
    </LandingLayout>
  );
}

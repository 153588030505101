import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import name from "../assets/companyName.png";
import logo from "../assets/logo.svg";
import { Squash as Hamburger } from "hamburger-react";
import { useState } from "react";
import { Link, useMatch } from "react-router-dom";
import Content from "../assets/content";

const RIGHT_FRAME_WIDTH = 100;
const NAV_BAR_HEIGHT = 80;
const LOGO_SIZE = 60;

function Nav({
  isActive,
  label,
  route,
}: {
  isActive: boolean;
  label: string;
  route: string;
}) {
  const [isHover, setHover] = useState(false);
  return (
    <Link to={route} style={{ textDecoration: "none", color: "black" }}>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          width: 110,
          padding: "0 15px 0 15px",
          position: "relative",
        }}
      >
        {isActive && (
          <div
            className="ba b--white"
            style={{
              width: "30%",
              backgroundColor: "white",
              position: "absolute",
              bottom: -2,
              left: "35%",
            }}
          >
            <div
              className="bb bw1"
              style={{
                top: -1,
                width: "50%",
                left: "25%",
                position: "absolute",
              }}
            />
          </div>
        )}
        <Typography variant="h5" fontWeight={isHover ? 500 : 400}>
          {label}
        </Typography>
      </div>
    </Link>
  );
}

function MobileNav({
  isActive,
  label,
  route,
}: {
  isActive: boolean;
  label: string;
  route: string;
}) {
  return (
    <Link to={route} style={{ textDecoration: "none", color: "black" }}>
      <div
        style={{
          width: 110,
          padding: "0 15px 10px 15px",
          position: "relative",
        }}
      >
        <Typography variant="h5" fontWeight={isActive ? 500 : 400}>
          {label}
        </Typography>
      </div>
    </Link>
  );
}

function MobileNavBar() {
  const isAbout = useMatch("/about");
  const isHome = useMatch("/");
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="fixed w-100 z-5">
      <div
        className={
          "flex flex-row justify-between w-100 bg-white b--near-white " +
          (isOpen ? "" : "bb")
        }
      >
        <Link to="/">
          <img
            src={logo}
            style={{
              paddingTop: 10,
              paddingLeft: 15,
              height: 35,
            }}
            alt="logo"
          />
          <img
            src={name}
            style={{
              paddingLeft: 10,
              paddingBottom: 7,
              height: 20,
            }}
            alt="logo"
          />
        </Link>
        <div style={{ paddingTop: 5, paddingRight: 10 }}>
          <Hamburger
            toggled={isOpen}
            duration={0.25}
            toggle={setOpen}
            size={24}
          />
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed left-0 shadow-4xl right-0 top-[3.5rem] p-5 pt-0 bg-neutral-950 border-b border-b-white/20"
          >
            <div
              className={
                "z-2 relative bg-white flex flex-column justify-start items-start pa3 pl4 b--near-white " +
                (isOpen ? "bb" : "")
              }
            >
              <MobileNav
                route="/"
                label={Content.nav.homeLabel}
                isActive={isHome != null}
              />
              <MobileNav
                route="/about"
                label={Content.nav.aboutLabel}
                isActive={isAbout != null}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function DesktopNavBar() {
  const isAbout = useMatch("/about");
  const isHome = useMatch("/");
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.only("lg"));
  const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const widthStyles = isLargeScreen
    ? {
        width: RIGHT_FRAME_WIDTH,
      }
    : isXLScreen
    ? { width: "6%" }
    : {};
  return (
    <div
      className="flex flex-row justify-between fixed z-3 bb w-100"
      style={{
        height: NAV_BAR_HEIGHT,
        minWidth: isLargeScreen
          ? theme.breakpoints.values.lg + RIGHT_FRAME_WIDTH
          : 0,
      }}
    >
      <div
        className="flex justify-center bg-white relative"
        style={{ width: "110px", top: 1 }}
      >
        <Link to="/">
          <img
            src={logo}
            style={{
              height: LOGO_SIZE,
              top: NAV_BAR_HEIGHT - LOGO_SIZE / 2,
              position: "relative",
            }}
            alt="logo"
          />
        </Link>
      </div>
      <div
        className="z-2 bg-white flex justify-end items-end pr3 br"
        style={{ flexGrow: 1 }}
      >
        <Nav
          label={Content.nav.homeLabel}
          route="/"
          isActive={isHome != null}
        />
        <Nav
          label={Content.nav.aboutLabel}
          route="/about"
          isActive={isAbout != null}
        />
      </div>
      <div className="bg-white" style={{ ...widthStyles }} />
    </div>
  );
}

export default function NavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isMobile) {
    return <MobileNavBar />;
  }
  return <DesktopNavBar />;
}

import { useMediaQuery, useTheme } from "@mui/material";
import LandingLayout from "./LandingLayout";
import ProductSection from "./ProductSection";
import HeroSection from "./HeroSection";

export default function Home() {
  const theme = useTheme();
  const notLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <LandingLayout>
      <div
        className={
          (isSmallScreen ? "" : "pa4 ") + (notLargeScreen ? "" : "br b--black")
        }
      >
        <HeroSection />
        <ProductSection sectionIndex={0} />
        <ProductSection sectionIndex={1} />
        <ProductSection sectionIndex={2} />
      </div>
    </LandingLayout>
  );
}
